import React from "react"
import ReactDOM from "react-dom"

import Router from "./Router";

import {isElement} from "../utility/utils.js";

const createReactClass = require('create-react-class');

const web_json_name = "web.json";

const ComponentClass = createReactClass({
    render() {
        return <div>
            <div ref="component"></div>
        </div>;
    }
});

export default class RoutableContainer {
    /**
     * @property container
     */
    set container(value) {

        const _container = value;

        if (!isElement(_container)) {
            _container = document.querySelector(value)
        }

        if (!isElement(_container)) {
            throw "Container value should be a doc element or queryString";
        }

        if (this.component) {
            // todo, for move instance to another container
            ReactDOM.unmountComponentAtNode(this._contaner);

        } else {
            const el = React.createElement(this.componentClass, {
                "router": this.router
            });
            const wrapper = ReactDOM.render(el, _container);
            this.wrapper = wrapper;

            if (this.wrapper.component.current) {
                this.component = this.wrapper.component.current.getWrappedInstance ? this.wrapper.component.current.getWrappedInstance() : this.wrapper.component.current;
                this.component.instance = this; /// ioc;
            }

            //register router to container
            this.router.delegateContainer = _container;
            this.router.onChange(() => {
                this._navigationDidChange()
            });
        }

        this._contaner = _container;
    }
    get container() {
        return this._contaner
    }


    /**
     * @property location
     */

    set location(value) {
        if (this._location != value) {

            if (this._location && this._location.indexOf(value) != -1) {
                return; // host property will change _location, so check is new value is part of old value
            }

            if (this._location) {
                this.clear();
            }

            if (this.host) {
                value = this.host + value
            }

            this._location = value;

            // auto load web.json
            if (this.autoLoadConfig) {
                this.configURL = value + web_json_name;
            }
        }
    }

    get location() {
        return this._location;
    }


    /**
     * @property configURL
     */
    set configURL(value) {
        if (this._configURL != value) {


            if (this._configURL) {
                this.clear();
            }

            if (this.host && value.indexOf(this.host) == -1) {
                value = this.host + value
            }

            this._configURL = value;
            this._fetch_config_file(value);
        }
    }
    get configURL() {
        return this._configURL;
    }

    set config(value) {
        let valueObjet = null;
        if (value) {
            if (typeof value === 'string') {
                try {
                    valueObjet = JSON.parse(value);
                } catch (error) {
                    this.configDataGetError(error)
                    const _event = new CustomEvent("configDataError", {
                        detail: { error },
                        bubbles: true,
                        cancelable: true
                    });
                    this._contaner.dispatchEvent(_event);
                }
            }
            if (typeof value === 'object') {
                valueObjet = value;
            }
        }
        if (valueObjet) {
            this.accessible(valueObjet).then(this.saveConfig).catch((error) => {
                this.configDataGetError(error)
                const _event = new CustomEvent("configDataError", {
                    detail: { error },
                    bubbles: true,
                    cancelable: true
                });
                this._contaner.dispatchEvent(_event);
            })
        }
        this.configData = valueObjet;
    }
    get config() { return this.configData; }

    set content(value) {
        this._content = value;
    }
    get content() { return this._content }

    set url(value) {
        this.router.navigateTo(value);
    }

    get url() {
        return this.router.queryString
    }


    /**
     * @constructor
     */
    constructor() {
        /**
         * @property component
         * @type {Component}
         */
        this.component = null;

        /**
         * @property componentClass
         * @type {ReactComponent}
         */

        this.componentClass = ComponentClass;

        /**
         * @property router
         * @type {Router}
         */
        this.router = new Router();
        /**
         * @property configData
         * @type {Object}
         */
        this.configData = null;

        /**
         * @property defaultURI
         * @type {String}
         */
        this.defaultURI = null;
        /**
         * @property host
         * @type {String}
         */
        this.host = null;
        this._changeFN = null;
        this._loadFN = null;
        this.autoLoadConfig = true;

        this.saveConfig = this.saveConfig.bind(this);
        this.accessible = this.accessible.bind(this);
    }

    onChange(value) {
        this._changeFN = value;
    }

    onLoad(value) {
        this._loadFN = value;
    }

    // componentDidMount(component) {
    // }

    configDataDidLoad(data) {

    }

    configDataGetError(e) {
        console.error('Path of config or config file is not correct: ', e);
    }


    navigationDidChange(url) {

    }


    _navigationDidChange() {
        //fire navigation change
        let fire = true;
        if (this._changeFN) {
            fire = this._changeFN.call(null, this.router.queryString, this.router)
        }

        try {
            const _event = new CustomEvent("change", {
                detail: {
                    data: this.router.queryString
                },
                bubbles: true,
                cancelable: true
            });
            const cancelled = this._contaner.dispatchEvent(_event);

            if (cancelled === false) {
                return false;
            }
        } catch (e) {

        }

        if (fire !== false) {
            this.navigationDidChange(this.router.queryString);
        }

        try {
            const _event = new CustomEvent("select", {
                detail: {
                    data: this.router.queryString
                },
                bubbles: true,
                cancelable: true
            });
            const cancelled = this._contaner.dispatchEvent(_event);

            if (cancelled === false) {
                return false;
            }
        } catch (e) {

        }


    }


    navigateTo(value) {
        this.url = value;
    }


    clear() {
        //
        this.defaultURI = null;
        this.configData = null;
        this._location = null;
        this._configURL = null;
        this._changeFN = null;
        this._loadFN = null;
        this._content = null;

        this.router.clear();
    }

    unmount() {
        this.clear();
        ReactDOM.unmountComponentAtNode(this._contaner);
    }

    /**
     * @method
     * @param url
     * @private
     */
    _fetch_config_file(url) {
        // var commitid = document.querySelector("[name=commitid]");
        // var id = commitid ? commitid.getAttribute("content") : Math.random();
        // var self = this;
        var withCredentials = !!url.match(/devnet.*.cisco.com/);

        if (this.controller) {
            this.controller.abort();
        }

        const controller = new AbortController();
        this.controller = controller;

        fetch(url, {
            credentials: withCredentials ? 'include' : 'same-origin',
            signal: controller.signal,
        }).then((res) => res.json()).then(this.accessible).then(this.saveConfig).catch((error) => {
            if (!controller.signal.aborted) {
                this.configDataGetError(error)
                const _event = new CustomEvent("configDataError", {
                    detail: { url, error },
                    bubbles: true,
                    cancelable: true
                });
                this._contaner.dispatchEvent(_event);
            }
        }).finally(() => {
            this.controller = null;
        });
    }


    on(eventName, eventHandler, useCapture = false) {
        if (this._contaner) {
            this._contaner.addEventListener(eventName, eventHandler, useCapture)
        }
    }

    off(eventName, eventHandler, useCapture = false) {
        if (this._contaner) {
            this._contaner.removeEventListener(eventName, eventHandler, useCapture)
        }
    }

    accessible(res) {
        const { meta } = res;
        const { access_control = 'public', profile_id_whitelist = '', role_allowlist = '' } = meta;
        return new Promise((resolve, reject) => {
            if (!this.checkAccess) return resolve(res);
            if (access_control === "public") {
                return resolve(res);
            } else if (!window.PlatformSDK || !window.PlatformSDK.isLoggedIn()) {
                return reject(new Error(access_control.startsWith("access_level") ? 'cco_login_required' : 'login_required'));
            } else {
                if (access_control === 'sociallogin') {
                    return resolve(res);
                }
                
                if (access_control.startsWith('access_level')) {
                    const accessLevel = access_control.slice(-1)
                    return PlatformSDK.fetchUserAccount().then(({ data }) => {
                        if (data.provider === "ciscosso") {
                            if (data.accesslevel >= accessLevel) {
                                return resolve(res);
                            }
                        }
                        throw new Error('forbidden');
                    }).catch(reject);
                }
    
                if (access_control === 'profile_id_whitelisted') {
                    return PlatformSDK.fetchUserAccount().then(({ data }) => {
                        const whiteList = profile_id_whitelist.split(',');
                        if (whiteList.includes(data.profileID)) {
                            return resolve(res);
                        }
                        throw new Error('forbidden');
                    }).catch(reject);
                }
    
                if (access_control == "role_based_allowlist") {
                    return PlatformSDK.fetchUserAccount().then(({ data }) => {
                        const roleAllows = role_allowlist.split(',');
                        const permissions = (data.permissions || []);
    
                        if (permissions.find((permission) => roleAllows.includes(permission))) {
                            return resolve(res);
                        }
                        throw new Error('forbidden');
                    }).catch(reject);
                }
            }
            return reject(new Error('forbidden'));
        });
    }

    saveConfig(data) {
        const _event = new CustomEvent("configDataLoaded", {
            detail: {
                data,
            },
            bubbles: true,
            cancelable: true
        });
        this._contaner.dispatchEvent(_event);

        this.configData = data;
        this.configDataDidLoad(data);
        if (this._loadFN) {
            this._loadFN.call(null, this.component)
        }

        const _init_event = new CustomEvent("init", {
            detail: {
                data: this
            },
            bubbles: true,
            cancelable: true
        });
        this._contaner.dispatchEvent(_init_event);
    }
}


