import React from 'react';
import './Slider.scss';

const DIR_LEFT = 'left';
const DIR_RIGHT = 'right';

export default class Slider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      direction: DIR_LEFT,
      playIndex: 0,
    };
  }

  componentDidMount() {
    this.play();
  }

  componentWillUnmount() {
    this.stop();
  }

  play() {
    this.timer = setTimeout(() => {
      this.setState({
        direction: DIR_LEFT,
        playIndex: (this.state.playIndex + 1) % this.props.children.length
      });

      this.play();
    }, 10000);
  }

  stop() {
    clearTimeout(this.timer);
  }

  slideLeft() {
    this.stop();
    this.play();

    this.setState({
      direction: DIR_LEFT,
      playIndex: (this.state.playIndex + 1) % this.props.children.length
    });
  }

  slideRight() {
    this.stop();
    this.play();

    this.setState({
      direction: DIR_RIGHT,
      playIndex: this.state.playIndex == 0 ? this.props.children.length - 1 : this.state.playIndex - 1
    });
  }

  slideTo(index) {
    this.stop();
    this.play();

    const direction = this.state.playIndex > index ? DIR_RIGHT : DIR_LEFT;

    this.setState({
      direction: direction,
      playIndex: index
    });
  }

  _onClickIndicatorItem = (e) => {
    var index = e.target.dataset.index;
    this.slideTo(index);
  }

  render() {
    let slideCount = this.props.children.length;
    let {direction, playIndex} = this.state;
    return (
      <div className='pubhub-new-slider'>
        {
          this.props.children.map((child, index) => {
            return this.renderSlide(direction, playIndex,
              child, index, slideCount);
          })
        }
        {
          this.renderSlideIndicator(playIndex, this.props.children)
        }
      </div>
    );
  }

  renderSlide(direction, playIndex, child, index, slideCount) {
    let className = '';
    let lastPlayIndex;
    if (direction == DIR_LEFT) {
      lastPlayIndex = playIndex == 0 ? (slideCount - 1) : (playIndex - 1);
      if (index == playIndex) {
        className = ' pubhub-new-slide-in-left';
      }
      else if (index == lastPlayIndex) {
        className = ' pubhub-new-slide-out-left';
      }
    }
    else {
      lastPlayIndex = (playIndex + 1) % slideCount;
      if (index == playIndex) {
        className = ' pubhub-new-slide-in-right';
      }
      else if (index == lastPlayIndex) {
        className = ' pubhub-new-slide-out-right';
      }
    }

    return (
      <div key={index}
           className={"pubhub-new-slider-slide" + className}>
        {child}
      </div>
    );
  }

  renderSlideIndicator(playIndex, children) {
    return (
      <div className='pubhub-new-slider-indicator'>
        {
          children.map((child, index) => {
            let className = index == playIndex ? ' pubhub-new-slider-indicator-item-activated ' : '';
            return (
              <span className={'pubhub-new-slider-indicator-item' + className}
                    key={index}
                    data-index={index}
                    onClick={this._onClickIndicatorItem}>
                              {index == playIndex ? <span className='activated-item-progress-bar'></span> : ''}
                            </span>
            );
          })
        }
      </div>
    );
  }
}
