/**
 * Created by aaikepae on 1/29/17.
 */

export function parseURL(url, relativeLocation, rootLocation) {
    return handleURL(url, relativeLocation, rootLocation, "/");
}

export function parseImageURL(url, relativeLocation, rootLocation) {
    return handleURL(url, relativeLocation, rootLocation, PubHub.SDKURL);
}

function handleURL(url, relativeLocation, rootLocation, pubhubCaseBaseURL) {
    if (!url) {
        return null
    }

    if (url.indexOf("https://") == 0
        || url.indexOf("http://") == 0
        || url.indexOf("//") == 0
        || url.indexOf("mail") == 0
        || url.indexOf("#") == 0) {
        return url;
    }

    const Str_PubHub = "/pubhub/";
    const Str_PubHub_Length = Str_PubHub.length;
    if (url.substr(0, Str_PubHub_Length) == Str_PubHub) {
        return pubhubCaseBaseURL + url.substr(Str_PubHub_Length);
    }

    if (rootLocation && (url.indexOf("./") == 0 || url.indexOf("/") == 0)) {
        var _url = url;

        const Str_DotSlash = "./";
        if (url.indexOf(Str_DotSlash) == 0) {
            _url = url.substr(Str_DotSlash.length)
        }

        const Str_Slash = "/";
        if (url.indexOf(Str_Slash) == 0) {
            _url = url.substr(Str_Slash.length)
        }
        return rootLocation + _url;
    }

    return relativeLocation + url;
}

export function parseLink(url, location, context = null) {
    if (!url) {
        return null
    }

    if (url.indexOf("http") == 0 || url.indexOf("//") == 0 || url.indexOf("mail") == 0 || url.indexOf("#") == 0) {
        return url;
    } else if (url.endsWith('.pdf')) {
      return (location) ? location + url : url; 
    } else if (url.indexOf(".") == -1) { // for /site/ or /sites/ or related link
        return url;
    } else {
        const filename = url.substr(0, url.lastIndexOf("."));
        return "#/" + filename
    }
}
