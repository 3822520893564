/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState.content, action) {
  if (action.type == "FETCH_CONTENT_FULFILLED") {
    return {...state, data: action.payload.data, failed: false }
  } else if (action.type == "FETCH_CONTENT_REJECTED") {
    return {...state, failed: true }
  } else if (action.type == "CLEAR") {
    return null
  }
  return state
}


