/**
 * Created by aaikepae on 11/3/16.
 */

import {parseURL, parseImageURL} from "./link";


//http://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object
export function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrom)
    return obj instanceof HTMLElement;
  }
  catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have. (works on IE7)
    return (typeof obj === "object") &&
      (obj.nodeType === 1) && (typeof obj.style === "object") &&
      (typeof obj.ownerDocument === "object");
  }
}

export function generateLink(text) {
  var string = text.replace(/[\r\n]/g, '-').replace(/[^\w\s]/gi, '').replace(/\s+/g, "-").replace(/(-+)$/, "").replace(/^(-+)/, "").replace(/--./, "-").toLowerCase();
  return string


}

export function includeCodeSection(text) {
  return text.match(/<pre class="?.+"><code/);
}


export function updateRelatedLink(wrapper, location, rootlocation) {


  //update href to absolute url
  Array.from(wrapper.querySelectorAll("[href]")).forEach(function (link) {
    const href = parseURL(link.getAttribute("href"), location, rootlocation);
    if (href) {
      link["href"] = href;
      if (href.startsWith("http") || href.startsWith("//")) {
        link["target"] = '_blank';
      }
    }

  });

  //update src to absolute url
  Array.from(wrapper.querySelectorAll("iframe[src]")).forEach(function (link) {
    link.setAttribute("src", parseImageURL(link.getAttribute("src"), location, rootlocation) + "#" + window.location.origin + window.location.pathname);
  });

  //update src to absolute url
  Array.from(wrapper.querySelectorAll("[src]:not(iframe)")).forEach(function (link) {
    link.setAttribute("src", parseImageURL(link.getAttribute("src"), location, rootlocation) + "#" + document.domain);
  });

  //add attribute to internal link
  Array.from(wrapper.querySelectorAll("a[href^='#']")).forEach(function (link) {
    //data-tag="navigation-item" data-url={data.url}
    link.setAttribute('data-tag', 'navigation-item');
    link.setAttribute('data-url', link.getAttribute("href").substr(1));
  });

  if (PubHubDocInstance && PubHubDocInstance.router.mode === 'history') {
    Array.from(wrapper.querySelectorAll("a[href^='#!']")).forEach(function (link) {
      const href = link.getAttribute("href");
      link.href = PubHubDocInstance.router.baseURL + href.substring(2).replace('/', '/#');
    });
  }

  // // update html related html tag
  Array.from(wrapper.querySelectorAll("a[href$='.html']")).forEach(function (link) {
    if(link.href.startsWith("http")){
      return
    }
    // "https://pubhub.cisco.com/media/test-webflow-project/eb57e5d0e212994a1248d570d0ac31046bbc6085/about.html => about/
    link.href = link.href.slice(link.href.lastIndexOf("/") + 1 ,".html".length * -1) + "/";
    link.target = "_parent";
  });


  return wrapper;
}


export function loadJSFiles(wrapper, location, callback) {

  //js
  const scripts = Array.prototype.slice.apply(wrapper.querySelectorAll('script[src]'));
  let urls = scripts.map(function (script, index) {
    return script.getAttribute("src");
  });

  let promises = urls.map(function (url, index) {  // create promise for loading files
    return loadJSFile(parseURL(url, location));
  });
  // insert html after all js files loaded
  Promise.all(promises).then(function () {
    console && console.log("loaded");
    callback.call(null);
  }, function () {
    console && console.log("error during loading js files");
    callback.call(null);
  });
}

export function loadJSFile(url, config = {}) {
  return new Promise(function (resolve, reject) {

    let _url = url;

    // cache the vendor js
    if(_url.startsWith("http") && document.querySelector("[src*='"+_url+"']")){
      resolve(this);
      return
    }

    try {
      if (!_url.startsWith("http") && PubHub.instance.location) {
        _url = parseURL(_url, PubHub.instance.location, PubHub.instance.location);
      }
    } catch (e) {

    }

    const script = document.createElement('script');
    script.src = _url;
    script.async = !!config["async"];  // async loading

    if (config["defer"]) {
      script.defer = !!config["defer"];
    }
    script.onload = function () {
      resolve(this);
    };
    script.onerror = function () {
      reject(this);
    };
    document.body.appendChild(script);
  });
}


export function loadCSSFile(url, config = {}) {
  return new Promise(function (resolve, reject) {
    const link = document.createElement('link');
    let _url = url;

    try {
      if (!_url.startsWith("http") && PubHub.instance.location) {
        _url = PubHub.instance.location + _url;
      }
    } catch (e) {

    }


    link.href = _url;
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";

    link.onload = function () {
      resolve(this);
    };
    link.onerror = function () {
      reject(this);
    };
    document.head.appendChild(link);
  });
}

export function removeVideoAutoPlay(wrapper) {
  Array.from(wrapper.querySelectorAll("video")).forEach(function (video) {
    video.removeAttribute("autoplay");
  });
  return wrapper;
}

export function removeElements(wrapper, selector) {
  Array.from(wrapper.querySelectorAll(selector)).forEach(function (el) {
    el.parentNode.removeChild(el);
  });
  return wrapper;

}


export function executeHTMLScript(wrapper) {
  const scripts = wrapper.querySelectorAll("script");
  Array.from(scripts).forEach(function (script) {
    eval(script.text);
  })
}

export function getHtml(el, selector) {
  if (!el) {
    return '';
  }

  if (!selector) {
    return el.innerHTML.trim();
  }

  let child = el.querySelector(selector);
  return child ? child.innerHTML.trim() : '';
}

export function getText(el, selector) {
  if (!el) {
    return '';
  }

  if (!selector) {
    return el.textContent.trim();
  }

  let child = el.querySelector(selector);
  return child ? child.textContent.trim() : '';
}

export const QueryString = function() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}();

