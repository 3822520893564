import axios from 'axios'
function getAPIBase() {
	return PubHub.APIHost + '/v1/social/feeds/'
}
// const blogRoot = 'https://blogs.cisco.com';
export function fetchBlogs(catalog, pageSize) {
	catalog = catalog || "developer"
	if (typeof catalog == 'object') {
		let opts = catalog;
		pageSize = opts.pageSize || pageSize;
	}

	let url = getAPIBase() + catalog;

	let promise = axios.get(url, {
		params: {
			detail: true,
			offset: 0,
			limit: pageSize || 20,
			type: 'rss'
		}
	}).then(resp => {
		let data = resp.data;
		let posts = data.posts || [];
		return posts.map(post => {
			let creator = (post.author || {}).name;
			return Object.assign({}, post, {
				creator,
				pubDate: post.created,
				description:post.content,
				// creatorUrl: post.author.link || getCreatorUrl(creatorId),
			})
		});
	});
	return promise;
/*
	let rtn = promise;
	if (isNeedAvatar) {
		rtn = promise.then(dataList => {
			return Promise.all(dataList.map(post => getCreatorInfoFromPost(post.link)))
				.then(creatorInfoList => {
					creatorInfoList.forEach((info, idx) => Object.assign(dataList[idx].author, info));
					return dataList;
				});
		});
	}
	return rtn;
	*/
}
/*
export function fetchBlogPageWithProxy(path, cb) {
	return axios.get('https://devnet-pubhub-api-proxy.testing.devnetcloud.com' + path, {
		headers: {
			"Target-Scheme-Host": blogRoot,
		},
		responseType: 'document'
	}).then(resp => {
		return cb ? cb(resp) : resp;
	});
}

export function getCreatorInfoFromPost(postLink) {
	const [prefix, path] = postLink.split(blogRoot);
	if (!(prefix === '' && path)) {
		return Promise.reject(new Error(`the post link ${postLink} is invalid`));
	}
	let info = {
		avatar: '',
		id: '',
		link: ''
	}
	return fetchBlogPageWithProxy(path).then(resp => {
		let doc = resp.data;
		if (doc) {
			let avatarImg = doc.querySelector('.main-content .avatar');
			if (avatarImg) {
				info.avatar = avatarImg.getAttribute('src');
			} else {
				console.warn('failed to get avatar in page:' + postLink);
			}
			let author = doc.querySelector('a[rel=author]');
			if (author) {
				let [prefix, creatorId] = author.href.split(blogRoot + '/author/');
				info.id = creatorId;
				info.link = blogRoot + '/' + creatorId;
			} else {
				console.warn('failed to get author link in page:' + postLink);
			}
		}
		return info;
	})
}

export function getCreatorAvatar(creatorId) {
	return fetchBlogPageWithProxy('/author/' + creatorId).then(resp => {
		let doc = resp.data;
		let avatar;
		if (doc) {
			let avatarImg = doc.querySelector('.author-image img');
			if (avatarImg) {
				avatar = avatarImg.getAttribute('src');
			} else {
				console.warn('failed to get avatar', creatorId);
			}
		}
		return avatar;
	})
}
*/