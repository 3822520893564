/**
 * Created by aaikepae on 4/5/17.
 */
import React from 'react';

export default class extends React.Component {
  render() {
    const { config } = this.props;
    if (!config) {
      return null;
    }

    const property = config.getAttribute('property');

    if (!property) {
      return null;
    }

    let meta = document.querySelector(`meta[property="og:${property}"]`);

    console.log(property, meta);

    if (!meta) {
      meta = document.createElement('meta');
      meta.setAttribute('property', `og:${property}`);
      document.head.appendChild(meta);
    }

    meta.setAttribute('content', config.innerText.trim());

    return null;
  }
}

