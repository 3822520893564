import React from "react";


export default class PubHubDoc extends React.Component {


  componentDidUpdate() {
    //clear
    this.mountDOC();
  }

  componentDidMount() {
    //deply for widgetloader to process
    setTimeout(this.mountDOC.bind(this), 0)
  }

  componentWillUnmount() {
    this.doc && this.doc.clear()
  }


  mountDOC() {
    if (!this.doc_config_url && !this.doc_location) {
      return
    }

    var config = {
      container: document.getElementById("pubhub-doc")
    };

    if (this.doc_config) {
      config = Object.assign({}, config, this.doc_config)

    }

    this.doc = window.PubHub.docInstance = PubHub.createDocPage(config);


    // this.doc.router.mode = "disable";

    if (this.doc_config_url) {
      this.doc.configURL = this.doc_config_url.trim();
    } else if (this.doc_location) {
      this.doc.location = this.doc_location.trim();
    }
  }

  render() {
    const {config, location, type} = this.props;

    if (!config) {
      return <span>Loading</span>
    }


    if (config.querySelector("location")) {
      this.doc_location = config.querySelector("location").innerText.trim();
    }

    if (config.querySelector("configurl")) {
      this.doc_config = config.querySelector("configurl").innerText.trim();
    }

    if (config.querySelector("config")) {
      try {
        this.doc_config = JSON.stringify(config.querySelector("configurl").innerText.trim());
      } catch (e) {

      }
    }


    return (
      <div className="pubhub-docs" id="pubhub-doc"/>
    );
  }
}

