/**
 * Created by aaikepae on 1/27/17.
 */


export function getCookies() {
  var str;
  try {
    str = document.cookie;
  } catch (err) {
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      console && console.error(err.stack || err);
    }
    return {};
  }
  return parse(str);
}


export function setCookie(name, value, options = {}) {

  //
  deleteCookie(name);

  var str = encode(name) + '=' + encode(value);

  if (null == value) {
    options.maxage = -1;
  }

  if (options.maxage) {
    options.expires = new Date(+new Date + options.maxage);
  }

  if (options.path) str += '; path=' + options.path;
  if (options.domain) str += '; domain=' + options.domain;
  if (options.expires) str += '; expires=' + options.expires.toUTCString();
  if (options.secure) str += '; secure';

  document.cookie = str;
}

export function deleteCookie(name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export function getCookie(name) {
  return getCookies()[name];
}

export function setCiscoCookie(name, value, options = {}) {
  if (window.location.origin.indexOf("cisco.com")) {
    setCookie(name, value, {...options, path: "/", domain: "cisco.com"})
  } else if (location.origin.indexOf("devnetcloud.com")) {
    setCookie(name, value, {...options, path: "/", domain: "devnetcloud.com"})
  } else {
    setCookie(name, value, {...options, path: "/"})
  }
}


function parse(str) {
  var obj = {};
  var pairs = str.split(/ *; */);
  var pair;
  if ('' == pairs[0]) return obj;
  for (var i = 0; i < pairs.length; ++i) {
    pair = pairs[i].split('=');
    obj[decode(pair[0])] = decode(pair[1]);
  }
  return obj;
}

function encode(value) {
  try {
    return encodeURIComponent(value);
  } catch (e) {
    console && console.debug('error `encode(%o)` - %o', value, e)
  }
}


function decode(value) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    console && console.console && console.debug('error `decode(%o)` - %o', value, e)
  }
}

