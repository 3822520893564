/**
 * Created by aaikepae on 1/16/17.
 */
import React from "react"
import {connect} from "react-redux"
import WidgetLoader from "../../common/base/WidgetLoader"
import SubscriptionListener from './Subscription'
import ScopedHTML from './ScopedHTML'

import SiteNotificationCtrl from "../../../widgets/microsite/siteNotification/SiteNotificationCtrl"

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

export class Site extends React.Component {
    componentWillMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let _container = window.PubHubSiteInstance.container;

        if (this.props.content && this.props.content.failed) {
            // <meta name="render:status_code" content="404">
            let meta = document.querySelector('meta[name="render:status_code"');
            if (meta) {
                meta.content = '404';
            } else {
                meta = document.createElement('meta');
                meta.name = 'render:status_code';
                meta.content = '404';
                document.head.append(meta);
            }

            const _event = new CustomEvent("contentError", {
                detail: {
                    data: {...this.props, container: this.refs["root"]}
                },
                bubbles: true,
                cancelable: true
            });
            _container.dispatchEvent(_event);

            return;
        }

        if (this.props.content && prevProps.content !== this.props.content) {
            const _event = new CustomEvent("load", {
                detail: {
                    data: {...this.props, content: this.props.content.data, container: this.refs["root"]}
                },
                bubbles: true,
                cancelable: true
            });
            _container.dispatchEvent(_event);
        }
    }

    render() {
        const {config, content, meta, components} = this.props;

        if (!config || !content || !meta) {
            return <span></span>
        }

       const content_el = config["scoped"]
           ? <ScopedHTML html={content.data} config={config} context={this}/>
           : <WidgetLoader html={content.data} config={config} context={this}/>


        const id = !meta["customizedStyle"] ? "pubhub-widgets" : meta["customizedStyle"];

        return <div id={id} className="pubhub-site-container" ref="root">
            <Alert stack={{limit: 3}} />
            <SubscriptionListener />
            {meta && meta.deprecated_banner && (
              <div className="pubhub-widgets">
                <SiteNotificationCtrl type="danger" description={meta.deprecated_banner} />
              </div>
            )}
            {content.failed ? (
                <div className="container dn-404"><a href="/">Go to Homepage</a></div>
            ) : (
                content_el
            )}
        </div>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        content: state.content,
        meta: state.meta,
        config: state.config
    };
}


export default connect(mapStateToProps, null, null, {withRef: true})(Site);
