/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseURL} from "Common/utility/link"

export default class Link extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }
    if (config.getAttribute('rel') === 'stylesheet' || config.getAttribute('ref') === 'stylesheet'
      || config.getAttribute('href') && config.getAttribute('href').lastIndexOf('.css') === config.getAttribute('href').length - 4) {
      const url = parseURL(config.getAttribute("href"), location, location);

      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = url;
      link.as = 'style';
      document.head.append(link);

      return <link rel="stylesheet" href={url}/>
    }
    return <link rel={config.getAttribute('rel')} hrefLang={config.getAttribute('hreflang')} href={config.getAttribute("href")} />
  }
}

