import qs from 'qs';

/**
 * Get parsed hash object
 * @returns {*}
 */
export const getParsedHash = () => {
    if (window.location.hash[1] === "!") {
        return qs.parse(window.location.hash.slice(2)); // remove !
    }
    return qs.parse(window.location.hash.replace("?","&").replace("#",""));
};

/**
 * generate hash, parameters could be one key:value, or a array of key values
 * like : [ [hashkey1,hashvalue1],[hashkey2, hashvalue2] ]
 * @param key
 * @param value
 * @returns {*}
 */
export const generateHash = (key, value) => {
    const parsedHash = getParsedHash();
    const keys = Array.isArray(key) ? key : [[key, value]];
    keys.map((item) => {
        const _key = item[0];
        const _value = item[1];

        if (!_value) {
            delete parsedHash[_key];
        } else {
            parsedHash[_key] = _value;
        }
    });

    // if hash is empty , return empty
    if (!Object.keys(parsedHash).length) {
        return "";
    }

    // SEO, use #! instead of #
    return `#${qs.stringify(parsedHash)}`;
};

/**
 * Update hash, parameters same as generateHash
 * @param key
 * @param value
 */
export const updateHash = (key, value) => {
    window.location.hash = generateHash(key, value);
};

/**
 * Replace hash, parameters same as generateHash
 * @param key
 * @param value
 */
export const replaceHash = (key, value) => {
    let hash = generateHash(key, value);
    if(!hash){
        hash = "#";
    }
    location.replace(hash);
};
