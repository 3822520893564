/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import { parseURL } from "../../../js/common/utility/link"

export default class Iframe extends React.Component {
  render() {
    const { config, location } = this.props;
    if (!config) {
      return null
    }
    const _src = config.getAttribute("src") || config.getAttribute("data-src");

    const src = _src ? parseURL(_src, location, location) : null;

    const frameborder = config.getAttribute("frameborder") || 1;

    const height = config.getAttribute("height") || 100;

    const width = config.getAttribute("width") || 100;

    const id = config.getAttribute("id");

    const _style = {
      "border": frameborder
    };

    const allowfullscreen = config.getAttribute("allowfullscreen");

    return (
      <iframe
        src={src}
        id={id}
        className={config.getAttribute("class")}
        style={_style}
        frameBorder={frameborder} height={height}
        width={width} 
        allowFullScreen={allowfullscreen}/>
    );
  }
}

