/**
 * Created by aaikepae on 6/15/17.
 */
export function logged() {
    return PlatformSDK.isLoggedIn()
}

export function onLoaderReady(callback) {
    // check is loader is ready
    try {
        if (window["PlatformSDK"]) {
            try {
                callback.call(null, window["PlatformSDK"]);
            } catch (e) {
                console && console.error(e)
            }

        } else {
            setTimeout(() => {
                onLoaderReady(callback);
            }, 66)
        }
    } catch (e) {
        console && console.error(e)
    }
}


export function loginRequired(callback) {
    try {
        console.warn("Please switch to the method in PlatformSDK ");
        callback.call(null);

    } catch (e) {
        console && console.error(e);
    }
}
