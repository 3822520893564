/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseURL} from "Common/utility/link"
import {loadJSFile} from "Common/utility/utils"

export default class ScriptFile extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }
    const _src = config.getAttribute("src") || config.getAttribute("data-src");
    loadJSFile(_src);
    return null
  }
}

