/**
 * Created by aaikepae on 4/12/17.
 */
/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import { parseURL } from "Common/utility/link"

export default class InlineScript extends React.Component {
  render() {
    const { config, location } = this.props;
    if (!config) {
      return null
    }

    if (config.getAttribute('type') != 'application/ld+json') {
      setTimeout(function () {
        eval(config.text);
      }, 0);
    }

    const attrs = {};
    for (let key in config.attributes) {
      const item = config.attributes[key];
      if (item.nodeName != 'class') {
        attrs[item.nodeName] = item.nodeValue;
      }
    }

    return (
      <script {...attrs}>
        {config.text}
      </script>
    );
  }
}



