/**
 * Created by aaikepae on 6/19/17.
 */
Element.prototype.queryChild = function (selector) {

  // return Array.from(this.children).filter(function (child) {
  //   return child.matches('selector')
  // }).shift();

  if (selector.substr(0, 1) == ".") {
    return this
  } else if (selector.substr(0, 1) == "#") {
    return this
  } else {
    return Array.from(this.children).filter(function (child) {
      return child.tagName && child.tagName.toLowerCase() == selector.toLowerCase();
    }).shift();
  }
};

Element.prototype.queryAll = function (selector) {
  return Array.from(this.querySelectorAll(selector));
};



export function removeElement(el) {
    if (typeof  el.remove == 'function') {
        el.remove()
    } else {
        el.outerHTML = '';
    }
}