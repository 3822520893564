/**
 * Created by aaikepae on 6/9/17.
 */


import React from "react";
import {logged} from "../../../js/common/utility/socialLogin"

const IfLogin = {
  render: function (props) {
    this.props = props;
    if (logged()) {
      return props.node;
    } else {
      return document.createElement("span")
    }
  }
};

export default IfLogin


