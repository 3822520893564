/**
 * Created by aaikepae on 1/27/17.
 */


export function getLanguage() {
  const language = navigator.language || navigator.userLanguage;
  return language ? language.substr(0, 2) : "en"
}

