import React from "react";
import {subscribe} from "../../../common/utility/subscription"
import Alert from 'react-s-alert';


export default class extends React.Component {
    subscribe = (topicID) => {
        subscribe(topicID).then(() => {
            Alert.success('Successfully subscribed!', {
                effect: 'slide'
            });
        }).catch((res) => {
            try {
                Alert.warning(res.response.data.Message, {
                    effect: 'slide'
                });
            } catch (e) {
                Alert.warning("Something went wrong!", {
                    effect: 'slide'
                });
            }

        })
    }

    handleClick = (event) => {
        // detect
        // Patch function to handle event.path not supported in Safari and Firefox
        const path = event.path || (event.composedPath && event.composedPath());
        let subscriptionElement = path.find((item) => item.tagName === "A" && (item.getAttribute("href") || '').startsWith("#subscription"));

        if (subscriptionElement) {
            // get topicID
            const topicID = (subscriptionElement.getAttribute("href") || '').split("#subscription=").pop();
            if (topicID) {
                // open social login
                if (PlatformSDK.openProtectedData("subscription=" + topicID)) {
                    this.subscribe(topicID)
                }

            }

            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    };

    componentDidMount() {
        //
        window.addEventListener('click', this.handleClick, true)

        if (window["PlatformSDK"]) {
            // if user login back from social login
            PlatformSDK.resumeProtectedData((value) => {
                if (!value || !value['startsWith'] || !value.startsWith("subscription")) {
                    return true
                } else {
                    this.subscribe(value.split("subscription=").pop())
                }
            })
        }

    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick, true)
    }

    render() {
        return null
    }
}


var testingpromise = function () {
    return new Promise(function (resolve, reject) {
        reject(
            {
                "responseJSON": {
                    "Message": "Already subscribed"
                }
            });
    })
};
