import React from 'react';
import ConfigParser from '../../../js/common/utility/configParser';

export default function wrap(component) {
    return (
        class WidgetWrapper extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    config: null
                };
            }
            componentDidMount() {
                const config = this.props.config;
                if (!config) {
                    return;
                }
                
                const data = ConfigParser.parse(config, component.defaultProps);
        
                this.setState({ config: data });
            }
            render() {
                if (!this.state.config) {
                    return <div></div>
                }
        
                return React.createElement(component, this.state.config);
            }
        }
    );
}