/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseImageURL} from "Common/utility/link"

export default class extends React.Component {
  render() {
    const { config, location } = this.props;
    if (!config) {
      return null
    }


    const title = config.innerText.trim();

    document.title = `${title} - Cisco DevNet`;

    return null;
  }
}

