/**
 * Created by aaikepae on 6/9/17.
 */


import React from "react";


const LoginRequired = {
  logged: function () {
    return window.PubHub.util.cookie.getCookie("devnet_token");
  },
  re_check_DevnetLoader: function (callback) {
    try {
      if (window["DevnetLoader"]) {
        callback.call(this);
      } else {
        setTimeout(() => {
          this.re_check_DevnetLoader(callback);
        }, 300)
      }
    } catch (e) {
      this.re_check_DevnetLoader(callback);
    }
  },
  register_on_close_event: function () {
    const self = this;
    DevnetLoader.SocialLogin.props.onClose = function () {
      if (!self.logged()) {
        setTimeout(DevnetLoader.SocialLogin.open, 0);
      }
    }
  },
  render: function (props) {
    this.props = props;

    if (this.logged()) {
      return props.node;
    } else {
      this.re_check_DevnetLoader(function () {
        if (this.logged()) {
          props.parent.update();
          // location.reload()
        } else {
          DevnetLoader.SocialLogin.open();
          this.register_on_close_event();
        }
      });

      return document.createElement("div");

    }
  }
};

export default LoginRequired



