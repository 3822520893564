import React from "react";
import Slider from './Slider';

function classNames(...args) {
  return args.filter(i => i).join(' ');
}

export default class NewRotatingBannerSection extends React.Component {
  render() {
    let pages = this.props.pages || [];
    return (
      <div className='pubhub-new-rotating-banner'>
        <div className="pubhub-new-rotating-banner-prev" onClick={this.onPrev}>
        </div>
        <div className="pubhub-new-rotating-banner-next" onClick={this.onNext}>
        </div>
        <Slider ref={dom => this.slider = dom}>
          {
            pages.map((page, index) => {
              return this.renderPage(page, index);
            })
          }
        </Slider>
      </div>
    );
  }

  renderPage(page, key) {
    const className = 'pubhub-new-rotating-banner-page';
    const target = page.action.target || '_blank';
    const actions = this.renderActions(page.actions, className);

    return (
      <div key={key} className={className} style={{ backgroundImage: 'url(' + page.pic + ')' }}>
        <div className='pubhub-new-rotating-banner-page-content'>
          <div className={className + '-title'} dangerouslySetInnerHTML={{ __html: page.title }}></div>
          <div className={className + '-description'} dangerouslySetInnerHTML={{ __html: page.description }}></div>
          {page.action.label && <a id={page.action.id} className={classNames(className + '-action', page.action.className)} href={page.action.href} target={target}>{page.action.label}</a>}
          {actions}
        </div>
      </div>
    );
  }

  renderActions(actions, className) {
    if (!actions || actions.length == 0) return null;

    const list = actions.map(action => {
      const target = action.target || '_blank';
      return (<a id={action.id} className={classNames(className + '-action-item', action.className)} href={action.href} target={target}>{action.label}</a>);
    });

    return (
      <div className={className + '-actions'}>
        {list}
      </div>
    );
  }

  onPrev = () => {
    this.slider.slideRight();
  }

  onNext = () => {
    this.slider.slideLeft();
  }
}
