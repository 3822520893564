/**
 * Created by aaikepae on 3/29/17.
 */

export function smoothScroll(el, to, duration, callback = null) {
  if (duration < 0) {
    return;
  }
  var difference = to - el.scrollTop;
  var perTick = difference / duration * 10;
  var scrollToTimerCache = setTimeout(function () {
    if (!isNaN(parseInt(perTick, 10))) {
      el.scrollTop += +perTick;
      smoothScroll(el, to, duration - 10, callback);
    } else {
      if (callback) {
        callback.call(null);
      }
    }
  }.bind(this), 10);
  return scrollToTimerCache
}

/**
 * https://gist.github.com/benjamincharity/6058688
 * @param to
 * @param duration
 * @returns {number}
 */
export function scrollTo(to, duration = 600, offset = 0) {
  if (!to || duration < 0) {
    return;
  }

  var duration = parseInt(duration);
  var offset = parseInt(offset);

  var comp;
  if (typeof to === 'string') {
    comp = document.querySelector(to);
  } else {
    comp = to;
  }

  if (!comp || !comp.getBoundingClientRect) {
    return;
  }

  var rect = comp.getBoundingClientRect()
  var difference = rect.top - offset;
  var perTick = difference / duration * 10;
  var scrollToTimerCache = setTimeout(function () {
    if (!isNaN(parseInt(perTick, 10))) {
      window.scrollTo(0, window.scrollY + perTick);
      scrollTo(to, duration - 10, offset);
    }
  }.bind(this), 10);
  return scrollToTimerCache
}
