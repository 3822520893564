/**
 * Created by aaikepae on 11/6/16.
 */

import Prism from 'prismjs';

import React from "react";

require('prismjs/plugins/line-numbers/prism-line-numbers');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');


//compatible with previous library
window.hljs = {
  highlightBlock : function (element) {
    const lang = element.classList[0]
    if(lang && !lang.startsWith("lang")){
      element.classList.add("lang-"+element.classList[0])
    }
    Prism.highlightElement(element)
  }
}

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.selectedIndex = null;

    this.handleTabClick = this.handleTabClick.bind(this);
    this.hightlightCode = this.hightlightCode.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);

    this.state = {
      copy: 'Copy',
    };
  }

  selectTab(index) {
    if (index !== null && this.selectedIndex != index) {
      this.selectedIndex = index;
      this.forceUpdate();
    }
  }

  handleTabClick(event) {
    const index = event.target.getAttribute("data-index");
    return this.selectTab(index);
  }

  createTabTitle(item, index) {
    const classes = "code-tab-title " + ((this.selectedIndex == item.title) ? "current" : "");
    return (
      <div className={classes} key={index} data-index={item.title} onClick={this.handleTabClick}>{item.title}</div>);
  }

  createTabContent(item, index) {
    let classes = "code-tab-content " + ((this.selectedIndex == item.title) ? "current" : "");

    return (
      <pre className={classes} key={index}>
        <span onClick={() => this.copyToClipboard(item.content.innerHTML)} className="copy-to-clipboard">
          {this.state.copy}
        </span>
        <code className={item.content.className} dangerouslySetInnerHTML={{ __html: item.content.innerHTML }} />
      </pre>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const isNextGroupsHaveSameTabIndex = nextProps.groups.filter((item) => item.title == this.selectedIndex);
      if (isNextGroupsHaveSameTabIndex.length !== 1) {
        this.selectedIndex = null;
      }
    }
  }

  componentDidMount() {
    if (window.PubHub.instance) {
      window.PubHub.instance.on('rendered', () => {
        this.hightlightCode()
      })
    }
    this.hightlightCode()
  }

  hightlightCode() {
    // Array.from(this.refs["tabs"].querySelectorAll(".code-tab-content code")).forEach((element, i) => {
    
    //   const language = element.getAttribute("class").toLowerCase();
    //   const innerHTML = element.innerHTML;
    //   // element.parentElement.classList.add("lang-"+language)
    
    // });
    Prism.highlightAll()
  }
  
  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.innerHTML = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({ copy: 'Copied!' });

    setTimeout(() => {
      this.setState({ copy: 'Copy' })
    }, 1000);
  }

  render() {
    const {groups} = this.props;

    const tabTitle = groups.map(this.createTabTitle.bind(this));
    const tabContent = groups.map(this.createTabContent.bind(this));
    return (<div className="code-tabs" ref="tabs" data-index={this.selectedIndex}>
      <div className="code-tab-title-container">
        {tabTitle}
      </div>
      <div className="code-tab-content-container">
        {tabContent}
      </div>
    </div>)
  }
}

