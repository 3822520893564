import React from "react";

import {updateRelatedLink, removeElements} from '../../../common/utility/utils';

export default class extends React.Component {
    constructor(props) {
        super(props);
        // this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }


    adjustIframeHeight = () => {
        this.intervalID = setInterval(() => {
            const height = Math.max(this.content.body.scrollHeight, 600)
            if (height != this.height) {
                this.iframe.setAttribute("height", height);
                this.height = height;
            }
        }, 300);
    }

    componentDidMount() {
        if (this.props.html) {

            const wrapper = document.createElement('div');
            wrapper.innerHTML = this.props.html;

            updateRelatedLink(wrapper, this.props.config.location);
            removeElements(wrapper, "meta");
            //
            //
            // // use webcomponent or iframe
            // const shadowRoot = this.refs["html"].attachShadow({mode: 'open'});
            // shadowRoot.innerHTML = wrapper.innerHTML;

            const iframe = this.iframe = document.getElementById("static_content_frame");
            const content = this.content = iframe.contentDocument || iframe.contentWindow.document;

            // inject JS
            const additionalHTML = `
            <style>

           
            
            </style>
            <script>
            
               
            </script>

            `;

            content.open()
            content.write(wrapper.innerHTML + additionalHTML)
            content.close();

            // for webflow
            const HTMLID = this.props.html.match(/data-wf-page="(\S*)"/) ? this.props.html.match(/data-wf-page="(\S*)"/)[1] : null;
            iframe.contentDocument.documentElement.setAttribute("data-wf-page",HTMLID);


            // content.body.innerHTML = wrapper.innerHTML + js;

            this.adjustIframeHeight();

            // sent event
            const topContainer = window.PubHubSiteInstance.container;
            const event = new CustomEvent("rendered", {
                detail: {},
                bubbles: true,
                cancelable: true
            });
            topContainer.dispatchEvent(event);
        }

    }

    componentWillUnmount() {
        clearInterval(this.intervalID)
    }


    render() {
        return (
            <iframe id="static_content_frame" width="100%" height="600px" scrolling="no" frameBorder="0"></iframe>
            // <div ref={"html"}></div>

        );
    }
}
