/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseImageURL} from "Common/utility/link"

export default class Video extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }

    const _src = config.getAttribute("src") || config.getAttribute("data-src");
    const src = parseImageURL(_src, location, location);
    const controls = config.getAttribute("controls");
    return <video src={src} alt={config.getAttribute("alt")} className={config.getAttribute("class")} width={config.getAttribute("width")} height={config.getAttribute("height")} controls={controls}/>
  }
}

