/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';
import {setCiscoCookie} from "../../common/utility/cookie"

export default function reducer(state = initialState.preference, action) {
  if (action.type == "UPDATE_LANGUAGE") {
    setCiscoCookie("devnet_language", action.payload);
    return {...state, language: action.payload}
  } else if (action.type == "UPDATE_PREFERENCE") {
    return {...state, [action.payload.key]: action.payload.value}
  } else if (action.type == "CLEAR") {
    return {...initialState.preference}
  }

  return state
}
