/**
 * Created by aaikepae on 7/20/17.
 */
import InfoCard from "./infoCard/InfoCard"
import PubHubDoc from "./pubhubDoc/PubHubDoc"
import LoginRequired from './LoginRequired/LoginRequired'
import IfNotLogin from './IfNotLogin/IfNotLogin'
import IfLogin from "./IfLogin/IfLogin"


const widgets = {
  "infocard": InfoCard,
  "pubhubdoc": PubHubDoc,
  "loginrequired": LoginRequired,
  "ifnotlogin": IfNotLogin,
  "iflogin": IfLogin
};

export default widgets
