import NewRotatingBanner from "./NewRotatingBanner/NewRotatingBanner";

const widgets = {
    HackIt: { loader: () => import('./HackIt/HackIt') },
    RotatingBanner: { loader: () => import('./RotatingBanner/RotatingBanner') },
    NewRotatingBanner,
    Forum: { loader: () => import('./Forum/Forum') },
    Subscription: { loader: () => import('./Subscription/Subscription') },
    Community: { loader: () => import('./Community/Community') },
};

export default widgets
