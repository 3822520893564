/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseImageURL} from "Common/utility/link"

export default class extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }

    let keywords = document.querySelector("meta[name=keywords]");

    if (!keywords) {
      keywords = document.createElement('meta');
      keywords.name = 'keywords';
      document.head.appendChild(keywords);
    }

    keywords.setAttribute("content", config.innerText.trim());

    return null;
  }
}

