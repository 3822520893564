/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseImageURL} from "Common/utility/link"

export default class extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }

    let description = document.querySelector('meta[name=description]');

    if (!description) {
      description = document.createElement('meta');
      description.name = 'description';
      document.head.appendChild(description);
    }

    description.setAttribute("content", config.innerText.trim());

    // let ogDescription = document.querySelector('meta[property="og:description"]');

    // if (!ogDescription) {
    //   ogDescription = document.createElement('meta');
    //   ogDescription.setAttribute('property', 'og:description');
    //   document.head.appendChild(ogDescription);
    // }

    // ogDescription.setAttribute("content", config.innerText.trim());

    return null;
  }
}

