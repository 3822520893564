const ELEMENT_NODE = 1;

function isConfigLeaf(el) {
    let containHtml = false;
    let elements = 0;
    const supportTags = {
        'img': '',
        'iframe': '',
        'a': '',
        'div': '',
        'span': '',
        'p': '',
        'section': '',
        'ul': '',
        'li': '',
        'i': '',
        'bold': '',
    }
    for (let i = 0; i < el.childNodes.length; i++) {
        if (el.childNodes[i].nodeType == ELEMENT_NODE) {
            elements = elements + 1;
            const tagName = el.childNodes[i].tagName.toLowerCase();
            if (tagName in supportTags) {
                containHtml = true;
                break;
            }
        }
    }
    return containHtml || elements == 0;
}

function parseStyle(el) {
    const { display, color, background, margin, fontSize, fontWeight, textAlign, verticalAlign } = el.dataset;
    const displayAtt = display ? { display } : undefined;
    const colorAtt = color ? { color } : undefined;
    const backgroundAtt = background ? { background } : undefined;
    const marginAtt = margin ? { margin } : undefined;
    const fontSizeAtt = fontSize ? { fontSize } : undefined;
    const fontWeightAtt = fontWeight ? { fontWeight } : undefined;
    const textAlignAtt = textAlign ? { textAlign } : undefined;
    const verticalAlignAtt = verticalAlign ? { verticalAlign } : undefined;
    
    return { ...displayAtt, ...colorAtt, ...backgroundAtt, ...marginAtt, ...fontSizeAtt, ...fontWeightAtt, ...textAlignAtt, ...verticalAlignAtt };
}

function rootToObject(el) {
    const style = parseStyle(el);
    return {
        id: el.dataset.id || el.id || undefined,
        className: el.dataset.class || el.className,
        style: style,
        href: el.dataset.href || el.href,
        target: el.dataset.target || el.target
    }
}

function elToObject(el, allowHtml) {
    const img = el.querySelector(el.tagName.toLowerCase() + ' > div.pubhub-widget-container > img');
    const { id, href, target, ...ds } = el.dataset;
    const className = ds.class;
    delete ds.class;
    
    return {
        id: id || el.id,
        className: className || el.className,
        style: parseStyle(el),
        href: href || el.href,
        target: target || el.target,
        dataset: ds,
        html: allowHtml ? el.innerHTML : undefined,
        textContent: el.textContent,
        image: img ? (img.src || img.dataset.src) : undefined
    }
}

function parseStringConfig(el, defaultValue = '', noTrim = false) {
    if (!el) {
        return defaultValue;
    }

    return noTrim ? el.textContent : el.textContent.trim();
}

function parseBoolConfig(el, defaultValue = false) {
    const text = parseStringConfig(el);
    if(!text) {
        return defaultValue;
    }

    return text == 'true';
}

function parseNumConfig(el, defaultValue = 0) {
    const text = parseStringConfig(el);
    const val = parseFloat(text);
    return isNaN(val) ? defaultValue : val;
}

function parseObjectConfig(el, allowHtml = true, defaultValue = undefined) {
    if (!el) {
        return defaultValue;
    }

    return elToObject(el, allowHtml);
}

function parseByType(el, allowHtml = true) {
    if (el.dataset.jstype == 'number') {
        return parseNumConfig(el);
    }
    if (el.dataset.jstype == 'boolean') {
        return parseBoolConfig(el);
    }
    if (el.dataset.jstype == 'string') {
        return parseStringConfig(el)
    }
    if (el.dataset.jstype == 'array') {
        return [];
    }
    return parseObjectConfig(el, allowHtml);
}

function xmlToObject(node, isRoot) {
    const isLeave = isConfigLeaf(node);
    const data = isRoot ? rootToObject(node) : parseByType(node, isLeave);

    if (isLeave) {
        return data;
    }

    const isArray = node.dataset.jstype == 'array';

    for (let i = 0, childNodes = node.childNodes; i < childNodes.length; i++) {
        let item = childNodes[i];
        if (item.nodeType == ELEMENT_NODE) {
            if (isArray) {
                data.push(xmlToObject(item, false));
            } else {
                const tagName = item.dataset.tagname || item.tagName.toLowerCase();
                data[tagName == 'richtitle' ? 'title' : tagName] = xmlToObject(item, false);
            }
        }
    }

    return data;
}

function schemaToSelector(schema, selectors, parentPath = '') {
    for (let key in schema) {
        const value = schema[key];
        if (typeof (value) == 'boolean') {
            selectors.push({ tagName: key, selector: parentPath + ' > ' + key, type: 'boolean' })
        } else if (typeof (value) == 'number') {
            selectors.push({ tagName: key, selector: parentPath + ' > ' + key, type: 'number' })
        } else if (typeof (value) == 'string') {
            selectors.push({ tagName: key, selector: parentPath + ' > ' + key, type: 'string' })
        } else {
            if (Array.isArray(value)) {
                selectors.push({ tagName: key, selector: parentPath + ' > ' + key, type: 'array' })
                if (value.length > 0) {
                    schemaToSelector(value[0], selectors, parentPath + ' > ' + key)
                }
            } else {
                if (value) {
                    schemaToSelector(value, selectors, parentPath + ' > ' + key)
                }
            }
        }
    }
}

function appendJsType(xml, schema) {
    const list = [];
    schemaToSelector(schema, list, xml.tagName);
    list.forEach(i => {
        if (i.type == 'array') {
            Array.from(xml.querySelectorAll(i.selector)).forEach(el => {
                el.dataset.jstype = i.type;
                el.dataset.tagname = i.tagName;
            });
        } else {
            const el = xml.querySelector(i.selector);
            if (el) {
                el.dataset.jstype = i.type;
                el.dataset.tagname = i.tagName;
            }
        }
    });
}

function handleSelector(element, selector) {
    return selector.indexOf('>') == 0 ? (element.tagName.toLowerCase() + ' ' + selector) : selector;
}

class Parser {
    constructor(element) {
        this.element = element;
    }
    
    parseString(selector, defaultValue = '', noTrim = false) {
        const target = this.element.querySelector(handleSelector(this.element, selector));
        return parseStringConfig(target, defaultValue, noTrim);
    }
    parseNumber(selector, defaultValue = 0) {
        const target = this.element.querySelector(handleSelector(this.element, selector));
        return parseNumConfig(target, defaultValue);
    }
    parseBool(selector, defaultValue = false) {
        const target = this.element.querySelector(handleSelector(this.element, selector));
        return parseBoolConfig(target, defaultValue);
    }
    parseObject(selector, defaultValue = undefined, allowHtml = true) {
        const target = this.element.querySelector(handleSelector(this.element, selector));
        const data = parseObjectConfig(target, allowHtml, undefined);
        if (!data) {
            return defaultValue;
        }

        const defaultVal = typeof(defaultValue) == 'string' ? { html: defaultValue } : (defaultVal || {}) 
        
        return { ...defaultValue, ...data}
    } 
}

export default class ConfigParser {
    static parseString(element, selector, defaultValue = '') {
        const target = element.querySelector(handleSelector(element, selector));
        return parseStringConfig(target, defaultValue);
    }
    static parseNumber(element, selector, defaultValue = 0) {
        const target = element.querySelector(handleSelector(element, selector));
        return parseNumConfig(target, defaultValue);
    }
    static parseBool(element, selector, defaultValue = false) {
        const target = element.querySelector(handleSelector(element, selector));
        return parseBoolConfig(target, defaultValue);
    }
    static parseObject(element, selector, defaultValue = undefined, allowHtml = true) {
        const target = element.querySelector(handleSelector(element, selector));
        const data = parseObjectConfig(target, allowHtml, undefined);
        if (!data) {
            return defaultValue;
        }

        const defaultVal = typeof(defaultValue) == 'string' ? { html: defaultValue } : (defaultVal || {}) 
        
        return { ...defaultValue, ...data}
    }
    static toParser (element) {
        return new Parser(element);
    }
    static parseRoot (config, className = '') {
        const obj = rootToObject(config);
        obj.className = className + (obj.className ? ' ' + obj.className : '');
        return obj;
    }
    static parse(config, defaultValue = {}) {
        appendJsType(config, defaultValue);
        const data = xmlToObject(config, true);
        return { ...defaultValue, ...data };
    }
}