/**
 * Created by aaikepae on 7/20/17.
 */
import Link from "./base/Link";
import File from "./base/File";
import ScriptFile from "./base/ScriptFile";
import Iframe from "./base/iframe";
import Img from "./base/Img";
import Video from "./base/Video";
import DynamicWidgets from "./base/DynamicWidgets"
import InlineScript from "./base/InlineScript";
import Title from "./base/Title";
import Description from "./base/Description";
import Keywords from "./base/Keywords";
import OpenGraph from "./base/OpenGraph";

import CodeTab from "./codeTab/CodeTab"


const widgets = {
    "link": Link,
    ".pubhub-file": File,
    "a[data-type=pubhub-file]": File,
    "img[data-src]": Img,
    "script[data-src]": ScriptFile,
    "script.pubhub-script-file": ScriptFile,
    "iframe": Iframe,
    ".pubhub-inlinescript": InlineScript,
    "inlinescript": InlineScript,
    ".pubhub-video": Video,
    "codetab": CodeTab,
    "pubhubtitle": Title,
    "pubhubdescription": Description,
    "pubhubkeywords": Keywords,
    "seotitle": Title,
    "seodescription": Description,
    "seokeywords": Keywords,
    "opengraph": OpenGraph,
    "widgets": DynamicWidgets
};

export default widgets
