/**
 * Created by aaikepae on 11/3/16.
 */

 import React from "react"
 import Navlist from "./NavList"
 import Search from "./Search"
 import VersionPicker from "./VersionPicker"
import MobileNav from "./MobileNav";
 
 const DOC_TYPES = [
     "warn",
     "success",
     "primary"
 ];
 const DEFAULT_TYPE = DOC_TYPES[0];
 
 export default class Nav extends React.Component {
     constructor() {
         super();
 
         this.state = {
             showDocLabel: false
         };
     }
 
     clickNavIconHandler = (event) => {
         const iconClass = "nav-item-icon";
         const csslist = Array.from(event.target.classList);
         if (csslist.indexOf(iconClass) != -1) {
             const sequence = event.target.getAttribute("data-sequence");
             const items = this.props.items;
 
             const model = sequence.split(".").reduce((acm, index) => {
 
                 if (typeof (acm) == "string") {
                     acm = items[parseInt(acm)]
                 }
 
                 return acm["items"] && acm["items"][parseInt(index)]
             });
 
             const _event = new CustomEvent("clickNavIcon", {
                 detail: {
                     item: model
                 },
                 bubbles: true,
                 cancelable: false
             });
             event.currentTarget.dispatchEvent(_event);
         }
     }
 
     toggleMenu() {
 
         if (this.getPlatform() !== "sm") {
             document.body.classList.remove("restrict_body");
             document.querySelector("html").classList.remove("restrict_body");
             return;
         }
 
         const scroll_el = this["refs"]["scroll"];
         const icon_el = this["refs"]["icon"];
         // const height = scroll_el.getAttribute("data-height") || "500";
         // scroll_el.style.height = height + "px";
 
         if (scroll_el.getAttribute("data-show") === "true") {
             scroll_el.classList.remove("show");
             scroll_el.setAttribute("data-show", "false");
             icon_el.classList.remove("close");
             // document.querySelector(".pubhub-doc-container").style.display = "block";
             document.querySelector("html").classList.remove("restrict_body");
             document.body.classList.remove("restrict_body");
             scroll_el.style.top = undefined;
             scroll_el.style.bottom = undefined;
         } else {
            scroll_el.classList.add("show");
            const rect = scroll_el.getBoundingClientRect();
            scroll_el.style.top = `${rect.top}px`;
            scroll_el.style.bottom = '0';
            scroll_el.setAttribute("data-show", "true");
            icon_el.classList.add("close");
            // document.querySelector(".pubhub-doc-container").style.display = "none"
            document.querySelector("html").classList.add("restrict_body");
            document.body.classList.add("restrict_body");
         }
     }
 
     getPlatform() {
         return this.refs.root.parentElement.getAttribute("data-platform");
     }
 
 
     render() {
         const { items, selected, config, meta, router } = this.props;
 
         if (!items || !meta) {
             return null
         }
 
         const docLabel = this.renderDocLabel(config);
         const docLabelDetail = this.renderDocLabelDetail(config);
         const navTitleText = docLabelDetail || this.props.meta.name;

         const isArticle = (meta.docTypes || '').toLowerCase().includes('article');
         
         return (
            <div className={"pubhub-nav-container " + config.layout + (config.expandNavigation ? " open" : "")} onClick={this.clickNavIconHandler} ref="root">
                {config.useNewNav ? (
                    <MobileNav {...this.props} docLabel={docLabel} navTitleText={navTitleText} />
                ) : (
                    <div className="pubhub-nav-title">
                        <div className="pubhub-nav-title-wrapper">
                            <div className="pubhub-nav-title-text">
                                {docLabel}
                                {navTitleText}
                            </div>
                            <div className="pubhub-nav-title-icon" onClick={this.toggleMenu.bind(this)} ref="icon" />
                        </div>
                    </div>
                )}
                <div className="pubhub-nav-scroll" ref="scroll">
                    <div className="pubhub-nav-body">
                        <VersionPicker meta={meta} router={router} />
                        {!isArticle && !config.enableAI && <Search router={this.props.router} />}
                        <Navlist items={items} selected={this.props.selected} config={this.props.config}
                            router={this.props.router} toggle={this.toggleMenu.bind(this)} />
                    </div>
                </div>
            </div>
         );
     }
 
     renderDocLabelDetail(config) {
         if (!this.state.showDocLabel) return null;
 
         const label = this.getDocLabel(config);
         if (!label) return null;
 
         return (
             <span className={"pubhub-nav-doc-label-text label-" + label.type}>
                 {label.text}
             </span>
         );
     }
 
     renderDocLabel(config) {
         const label = this.getDocLabel(config);
         if (!label) return null;
 
         return (
             <span className={"pubhub-nav-doc-label-icon label-" + label.type} onClick={this.onToggleDocLabelText}>
                 <i className="fas fa-lock"></i>
             </span>
         );
     }
 
     getDocLabel(config) {
         if (!config || !config["doc-label"]) {
             return null;
         }
 
         const label = config["doc-label"];
 
         const typeOfLabel = typeof (label);
 
         if (typeOfLabel == "string") {
             return {
                 type: DEFAULT_TYPE,
                 text: label
             }
         }
 
         if (typeOfLabel == "object") {
             const labelText = (label.text || "");
             if (!labelText) return "";
 
             return {
                 type: this.parseDocLabelType(label.type),
                 text: labelText
             }
         }
 
         return "";
     }
 
     parseDocLabelType(type) {
         const labelType = (type || "").toLowerCase();
         return DOC_TYPES.includes(labelType) ? labelType : DEFAULT_TYPE;
     }
 
     onToggleDocLabelText = () => {
         this.setState({ showDocLabel: !this.state.showDocLabel })
     }
 }
 
 