import React from "react";

export default class Element extends React.Component {
    render() {
        const { data, tagName, ...baseProps } = this.props;

        if (!data && !this.props.children) {
            return null;
        }

        var props = this.buildProps(baseProps, data || {});

        if (props.href) {
            return (<a {...props}>{this.props.children}</a>);
        }

        if (tagName) {
            return React.createElement(tagName, props, this.props.children);
        }

        return (<div {...props}>{this.props.children}</div>);
    }

    buildProps(baseProps, data) {
        const isStringData = typeof (data) == 'string';

        const html = baseProps.children ? {} : { dangerouslySetInnerHTML: { __html: isStringData ? data : data.html } };
        const commonProps = {
            ...html,
            style: data.style,
            className: ((baseProps.className || '') + ' ' + (data.className || '')).trim()
        }

        if (isStringData) {
            return { ...baseProps, ...commonProps };
        }

        const datasetProps = {};

        if (data.dataset) {
            for (var key in data.dataset) {
                const attr = this.parseDataSetAttr(key);
                datasetProps[attr] = data.dataset[key];
            }
        }

        if (!data.href) {
            return { ...baseProps, ...commonProps, ...datasetProps };
        }

        const linkProps = {
            href: data.href,
            target: data.target || ((data.href || '').startsWith('http') ? '_blank' : undefined),
        }

        return {
            ...baseProps,
            ...commonProps,
            ...datasetProps,
            ...linkProps
        };
    }

    parseDataSetAttr(camelString) {
        const upperChars = camelString.match(/([A-Z])/g);

        var str = camelString;
        if (upperChars) {
            for (var i = 0, n = upperChars.length; i < n; i++) {
                str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
            }
        }

        return 'data-' + str;
    };
}