/**
 * Created by aaikepae on 1/17/17.
 */
import React from "react"
import ReactDOM from "react-dom"
import BasicWidgets from '../../../widgets/basic/Widgets'
import CommonWidgets from '../../../widgets/common/Widgets'
import MicrosteSiteWidgets from '../../../widgets/microsite/WidgetsDynamic'
import DevCenterWidgets from '../../../widgets/devcenter/WidgetsDynamic'
import SiteWidgets from '../../../widgets/site/WidgetsDynamic'
import DNWWidgets from '../../../widgets/devnetexpress/WidgetsDynamic'
import { registerElement } from '../utility/customElements';
import { removeElement } from '../utility/element';


const widgets = { ...BasicWidgets, ...CommonWidgets, ...SiteWidgets, ...MicrosteSiteWidgets, ...DevCenterWidgets, ...DNWWidgets };


export function registerWidget(selector, cls) {
    if (!cls && selector["selector"]) {
        widgets[selector["selector"]] = selector;
    } else {
        widgets[selector] = cls;
    }
}

// register custom elements
(function (widgets) {
    for (let selector in widgets) {
        if (selector.indexOf(".") === -1 && selector.indexOf("[") === -1) {
            registerElement(selector, widgets[selector]);
        }
    }
})(widgets);

export default class WidgetLoader extends React.Component {

    constructor(props) {
        super(props);

        // store all widgets instance
        this.elements = [];
    }

    update(html, props = null) {
        const self = this;
        const _props = props || this.props;
        const originalHTML = html || _props.html;
        const config = _props.config || {};
        const context = _props.context || {};
        const container = this.refs["widgetContainer"];

        // create a temp div to convert html string to dom
        const wrapper = document.createElement("div");
        wrapper.innerHTML = originalHTML;

        let elements = [];

        //basic react template implementation
        //iterate all registered widgets
        //[todo] prioritize nested components
        Object.keys(widgets).map(function (selector) {
            const Widget = widgets[selector];
            //query all matched elements
            Array.from(wrapper.querySelectorAll(selector)).map(function (el) {
                //create react element

                let _el = null;

                if (typeof (Widget) === "function") {
                    let widget = React.createElement(Widget, {
                        "config": el,
                        "id": el.getAttribute("id"),
                        "type": el.getAttribute("type"),
                        "node": el,
                        "location": config.location,
                        "context": context,
                        "parent": self,
                        "wrapper": wrapper
                    });

                    _el = document.createElement("div");
                    _el.classList.add("pubhub-widget-container");
                    _el.setAttribute("data-selector", selector);
                    //render el to that place
                    ReactDOM.render(widget, _el);
                    //
                    el.parentNode.replaceChild(_el, el);

                    // store instance
                    elements.push(_el);

                } else if (Widget["render"]) {
                    _el = Widget.render({
                        "config": el.cloneNode(true),
                        "id": el.getAttribute("id"),
                        "type": el.getAttribute("type"),
                        "node": el,
                        "location": config.location,
                        "context": context,
                        "parent": self,
                        "wrapper": wrapper
                    });

                    if (_el) {
                        if (_el.outerHTML != el.outerHTML){
                            el.parentNode.replaceChild(_el, el);
                        }
                    }

                    // store instance
                    elements.push(_el);

                } else if (typeof Widget === 'object') {
                    el.classList.add('widget-loading');
                    Widget.loader()
                        .then(function(component) {
                            el.classList.remove('widget-loading');
                            const WidgetDefault = component.default
                            if (typeof (WidgetDefault) === "function") {
                                let widget = React.createElement(WidgetDefault, {
                                    "config": el,
                                    "id": el.getAttribute("id"),
                                    "type": el.getAttribute("type"),
                                    "node": el,
                                    "location": config.location,
                                    "context": context,
                                    "parent": self,
                                    "wrapper": wrapper
                                });
            
                                _el = document.createElement("div");
                                _el.classList.add("pubhub-widget-container");
                                _el.setAttribute("data-selector", selector);
                                //render el to that place
                                ReactDOM.render(widget, _el);
                                //
                                el.parentNode.replaceChild(_el, el);
            
                            } else if (WidgetDefault["render"]) {
                                _el = WidgetDefault.render({
                                    "config": el.cloneNode(true),
                                    "id": el.getAttribute("id"),
                                    "type": el.getAttribute("type"),
                                    "node": el,
                                    "location": config.location,
                                    "context": context,
                                    "parent": self,
                                    "wrapper": wrapper
                                });
            
                                if (_el) {
                                    if (_el.outerHTML != el.outerHTML){
                                        el.parentNode.replaceChild(_el, el);
                                    }
                                }
                            }

                            // store instance
                            elements.push(_el);
                        }).catch((err) => {
                            el.classList.remove('widget-loading');
                            console.error(err)
                        });
                }
            })
        });

        try {
            // compare with previous html
            if (container.innerHTML != wrapper.innerHTML) {

                //remount all react widgets
                this.elements.forEach((el) => ReactDOM.unmountComponentAtNode(el));

                //remove old content
                Array.from(container.childNodes).forEach((el) => removeElement(el));

                //append new wrapper;
                container.appendChild(wrapper);
                // container.innerHTML = wrapper.innerHTML;

                this.elements = elements;

                // sent event
                if (window.PubHub.instance) {
                    const topContainer = window.PubHub.instance.container;
                    const event = new CustomEvent("rendered", {
                        detail: {
                            data: {
                                elements,
                            },
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    topContainer.dispatchEvent(event);
                }
            }

        } catch (e) {
            console && console.error(e)
        }

    }


    componentDidMount() {
        const { html } = this.props;
        if (html) {
            setTimeout(() => {
                this.update(html, this.props)
            }, 0);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.html !== this.props.html) {
            this.update(nextProps.html, nextProps)
        }
    }

    componentWillUnmount() {
        //remount all react widgets
        this.elements.forEach((el) => ReactDOM.unmountComponentAtNode(el));
    }

    render() {
        return <div ref="widgetContainer" className="widgets" />;
    }
}


