/**
 * Created by aaikepae on 1/28/17.
 */
/**
 * Created by aaikepae on 1/9/17.
 */
import React from "react"
import {generate} from "Common/utility/infoCard"

export default class InfoCard extends React.Component {


  render() {
    const {config, location} = this.props;

    if (!config) {
      return <span>Loading</span>
    }

    const elements = generate(config, location, this.props.type);


    return (
      <div className="pubhub-info-card container-fluid">
        {elements.image}
        {elements.title}
        {elements.description}
        {elements.link}
      </div>
    );

  }
}
