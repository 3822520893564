/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import { parseImageURL } from "Common/utility/link"

export default class Img extends React.Component {
  render() {
    const { config, location } = this.props;
    if (!config) {
      return null
    }

    const _src = config.getAttribute("src") || config.getAttribute("data-src");
    const src = parseImageURL(_src, location, location);
    const loading = config.getAttribute("loading");
    const loadingProp = loading ? { loading } : {}

    return <img src={src}
      alt={config.getAttribute("alt")}
      className={config.getAttribute("class")}
      align={config.getAttribute("align")}
      {...loadingProp} />
  }
}

