import React from "react";

const SUCCESS = 'success';
const INFO = 'info';
const WARNING = 'warning';
const DANGER = 'danger';

const SVG_WARNING = `M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 
23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 
0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 
1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 
47-49t65-18 65 18 47 49z`;
const SVG_INFO = `M1152 1376v-160q0-14-9-23t-23-9h-96v-512q0-14-9-23t-23-9h-320q-14 
0-23 9t-9 23v160q0 14 9 23t23 9h96v320h-96q-14 0-23 9t-9 23v160q0 14 9 23t23 9h448q14 0 
23-9t9-23zm-128-896v-160q0-14-9-23t-23-9h-192q-14 0-23 9t-9 23v160q0 14 9 23t23 9h192q14 
0 23-9t9-23zm640 416q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 
103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z`;
const SVG_SUCCESS = `M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 
90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 
385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 
103 279.5 279.5 103 385.5z`;

const SVG_PATHS = { 
    [SUCCESS]: SVG_SUCCESS,
    [INFO]: SVG_INFO,
    [WARNING]: SVG_WARNING,
    [DANGER]: SVG_WARNING
}

export default class SiteNotificationCtrl extends React.Component {
    renderIcon(type) {
        const svgPathData = SVG_PATHS[type];

        if(!svgPathData){
            return null;
        }

        return (
            <svg viewBox="0 0 1792 1792">
                <path d={svgPathData} />
            </svg>
        );
    }

    render() {
        const props = this.props;
        const type = (props.type || INFO).toLowerCase();
        const baseClassName = 'pubhub-site-notification'
        const className = `${baseClassName} ${baseClassName}-${type}`.trim();
        const icon = this.renderIcon(type);

        return (
            <div className={className}>
                <div className='container'>
                    <div className={baseClassName + '-content'}>
                        <div className={baseClassName + '-icon-container'}>
                            <div className={baseClassName + '-icon'}>
                                {icon}
                            </div>
                        </div>
                        <div className={baseClassName + '-text-container'}>
                            <div className={baseClassName + '-title'}>{props.title}</div>
                            <div className={baseClassName + '-description'} dangerouslySetInnerHTML={{ __html: props.description }}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
