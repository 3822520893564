import axios from "axios";

export function subscribe(topicID) {
    if (!topicID) {
        return new Promise((resolve, reject) => {
            reject("Missing topicID")
        })
    }
    const url = PubHub.APIHost + "/v1/subscriptionhub/subscriptions/";
    const data = {
        "topicID": topicID
    }
    return axios(url, {
        method: "post",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        crossDomain: true
    })
}


export function checkSubscriptionStatus(topicID) {
    return new Promise((resolve, reject) => {

        if (!PlatformSDK.isLoggedIn()) {
            reject()
            return
        }

        const url = PubHub.APIHost + "/v1/subscriptionhub/subscriptions/";
        axios(url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
            crossDomain: true
        }).then((res) => {
            const subscription = res.data.find((item) => item.topicID == topicID)
            // if subscribed
            if (subscription && subscription["subscribed"] == "subscribed") {
                resolve(subscription)
            } else {
                reject()
            }
        }).catch(() => {
            reject()
        })
    })
}
