import {applyMiddleware, createStore} from "redux"

import thunk from "redux-thunk"
import promise from "redux-promise-middleware"

import reducer from "../reducers/"

import initialState from "./initialState"

const middleware = applyMiddleware(promise(), thunk);


//export default createStore(reducer, middleware)
export default createStore(reducer, initialState, middleware)
