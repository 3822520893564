/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseURL} from "Common/utility/link"

import {loadJSFile} from "Common/utility/utils"


const DynamicWidgets = {
  render: function (props) {

    const {config, location} = props;
    if (!config) {
      return null
    }
    const _src = config.getAttribute("src") || config.getAttribute("data-src");
    const url = parseURL(_src, location, location);

    if (window.PubHub[url]) {
      return props.node;
    } else {
      loadJSFile(url).then(() => {
        window.PubHub[url] = url;
        props.parent.update();
      });

      const ele = document.createElement("div");
      ele.innerHTML = "loading";
      return ele;

    }

  }
};


export default DynamicWidgets

