import React from "react";
import NewRotatingBannerSection from './NewRotatingBannerSection';

function getImgSrc(el, selector) {
  if (!el) {
    return '';
  }

  let child = el.querySelector(selector);
  return child && child.src ? child.src.trim() : '';
}

function getText(el, selector) {
  if (!el) {
    return '';
  }

  let child = el.querySelector(selector);
  return child ? child.textContent.trim() : '';
}

function getHtml(el, selector) {
  if (!el) {
    return '';
  }

  let child = el.querySelector(selector);
  return child ? child.innerHTML.trim() : '';
}

function getAll(el, selector) {
  if (!el) {
    return [];
  }
  return Array.from(el.querySelectorAll(selector));
}

export default class NewRotatingBanner extends React.Component {
  render() {
    let config = this.props.config;

    let props = {
      pages: getAll(config, 'page').map(page => {
        let actions = getAll(page, 'actions item').map(i => ({
          id: getText(i, 'id'),
          className: getText(i, 'classname'),
          label: getText(i, 'label'),
          href: getText(i, 'href') || undefined,
          target: getText(i, 'target'),
        }));

        return {
          title: getText(page, 'title'),
          description: getHtml(page, 'description'),
          action: {
            id: getText(page, 'id'),
            className: getText(page, 'classname'),
            label: getText(page, 'action label'),
            href: getText(page, 'action href') || undefined,
            target: getText(page, 'action target'),
          },
          actions: actions,
          pic: getImgSrc(page, 'img')
        };
      })
    };

    return (
      <NewRotatingBannerSection {...props} />
    );
  }
}
