/**
 * Created by aaikepae on 11/3/16.
 */
import initialState from '../store/initialState';

export default function reducer(state = initialState, action) {

  if (action.type == "FETCH_META") {
    return {...action.payload}
  }else if (action.type == "CLEAR") {
    return {}
  }

  return state
}
