import React from "react";

export function shouldUseWebComponent(type) {
    return ["oas-meta", "oas-api", "oas-model", "oas3-meta", "oas3-api", "oas3-model"].indexOf(type) != -1
}

export class WebComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setSwaggerSpec = this.setSwaggerSpec.bind(this);
    }
    setSwaggerSpec() {
        const { selected } = this.props;
        const type = selected.item.type;

        if (shouldUseWebComponent(type)) {
            const el = document.getElementById("swagger_spec");
            if (el) {
                el.setAttribute("spec", typeof selected.data === 'string' ? selected.data : JSON.stringify(selected.data));
            }
        }
    }
    componentDidMount() {
        this.setSwaggerSpec();
    }

    componentDidUpdate() {
        this.setSwaggerSpec();
    }

    render() {
        const { selected } = this.props;
        const type = selected.item.type;

        if (type.startsWith("oas3-")) {
            const tag = type.replace("oas3-", "dui-swagger-") + '-v3';
            return this.renderWidget(tag);
        }

        if (type.startsWith("oas-")) {
            const tag = type.replace("oas-", "dui-swagger-"); //hacky, oas-meta -> dui-swagger-meta
            return this.renderWidget(tag);
        }

        return '';
    }

    renderWidget(tag) {
        const html = `<${tag} id="swagger_spec">
            <style>@media (max-width: 768px) {.swagger-container { height: auto; } }</style>
        </${tag}>`;
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>
    }
}
