/**
 * Created by aaikepae on 4/5/17.
 */
import React from "react";
import {parseURL} from "Common/utility/link"

export default class Link extends React.Component {
  render() {
    const {config, location} = this.props;
    if (!config) {
      return null
    }
    const url = parseURL(config.getAttribute("href"), location, location);
    config.setAttribute("href", url);

    if (config.tagName == "A") {
      const attributes = Array.from(config.attributes).reduce(function (acm, item) {
        if (item.name == "class") {
          acm["className"] = item.value;
        } else if (item.name !== 'style') {
          acm[item.name] = item.value;
        }

        return acm
      }, {});
      return <a {...attributes} dangerouslySetInnerHTML={{__html: config.innerHTML}}/>
    }


    return <div ref={(nodeElement) => {
      nodeElement.appendChild(config)
    }}/>
  }
}

